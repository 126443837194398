<template>
  <div class="documents">
    <section>
      <div class="container">
        <div class="row">
          <div class="col-12 pt-5">
            <h2 class="text-center py-5  pt-md-0">Prochange GDPR & Privacy Policy</h2>
            <p class="pb-3">
              This GDPR & Privacy Policy constitutes a part of Prochange UAB Terms and Conditions (the “T&C”). The Terms
              and Conditions in this Privacy Policy shall have the same meanings as in the T&C except as otherwise
              provided herein.
            </p>
            <p class="pb-3">
              This GDPR & Privacy Policy explains how Prochange, use, process, disclose, share, transfer, and protect
              personal information obtained through Prochange and its partners. The terms “we,” “us,” and “our” refer to
              Prochange. When we ask for certain personal information from users, it is because we are required by
              applicable laws or government orders to collect such information, or it is relevant for our specified
              purposes.
            </p>
            <p class="pb-3">
              Please carefully read the full content of this GDPR & Privacy Policy. When visiting Prochange, regardless
              of whether or not you register for or log in the Prochange account, you acknowledge, understand, and
              consent to all articles described in this GDPR & Privacy Policy.We will not use your personal information
              for any purpose not covered in this GDPR & Privacy Policy or the T&C without prior notification to you and
              your consent.
            </p>
            <p class="pb-3">
              This Privacy Policy has incorporated elements from the General Data Protection Regulation (GDPR) as we act
              in accordance with its personal information processing rules within the European Economic Area (EEA). We
              utilize standard contract clauses, rely on the European Commission's adequacy decisions about certain
              countries, as applicable, and obtain your consent for these data transfers to third countries if required
              by applicable laws.
            </p>

            <h3 class="pb-3">
              1. Information We Collect
            </h3>
            <p class="pb-3">
              The followings are the types of information we collect when you register for and use Prochange services:
            </p>
            <h6 class="pb-3">
              1.1 Information You Provide to Us When Registering
            </h6>
            <p class="pb-3">
              When you create a Prochange Account, you provide us with your email address, name, date of birth,
              nationality, country code, gender, signature, utility bills, home address, password, and other information
              to help us identify you (“Identification Information”).
            </p>
            <h6 class="pb-3">
              1.2 Information We Collect to Comply with Regulatory Policies
            </h6>
            <p class="pb-3">
              To comply with global industry regulatory standards, local industry regulatory standards, and government
              orders in different aspects such as Anti-Money Laundering (AML), Know-Your-Client (KYC), and
              Counter-Terrorist Financing (CTF), Prochange is required to collect personal information in addition to
              Identification Information, such as identity documents (including passport, driver's license, national
              identity card, state ID card, tax ID number, passport number, driver's license details, national identity
              card details, visa information, etc.), proof of address, source of fund declaration, purposes of fund
              documents, and source of wealth (“Regulation Information”). We will explain to you the content and
              requirements of such personal information each time we collect information, and we reserve the right to
              change the content and requirements of the collected information as the global industry regulatory
              standards, local regulatory standards or government orders change. This provision applies to both personal
              and corporate Prochange Accounts.
            </p>
            <h6 class="pb-3">
              1.3 Information We Collect as You Use Prochange Services
            </h6>
            <p class="pb-3">
              1. <i>Service Usage Information</i><br>
              1. Through your access to and use of Prochange Services, we may monitor and collect tracking information
              related to usage including but not limited to your phone number, access date and time, device type and
              device identification, operating system and hardware setting, browser type, and information derived from
              SIM card, network operator, IP address, GPS, base station and WLAN (“Service Usage Information”). This
              information may be directly obtained by Prochange or through third-party service providers. The collection
              of Service Usage Information helps our systems to ensure that our interface is accessible for users across
              all platforms and can aid during criminal investigations.
            </p>
            <p class="pb-3">
              2. <i>Transaction Information</i><br>
              2. For all personal and corporate user accounts, we may collect transaction information as you use
              Prochange Services, including but not limited to deposit snapshots, account balances, trade history (such
              as transaction initiation, payment method, price, quantity, time, withdrawal and authorization
              information), order activity, and distribution history (“Transaction Information”). We collect such
              transaction information to monitor suspicious trading activity for user fraud protection, legal case
              resolution, and any other purposes disclosed in this GDPR & Privacy Policy.
            </p>
            <p class="pb-3">
              3. <i> Communication Information</i><br>
              3. You agree that, for the purposes disclosed in this GDPR & Privacy Policy, we are entitled to collect
              and use the information contained in or related to the communication that you send to us or generated
              through your use of Prochange Services (“Communication Information”), which includes:
            </p>
            <ul>
              <li class="pb-3">all messages, requests or other information you send in the course of your contact with
                Prochange;
              </li>
              <li class="pb-3">all communications and file attachments in connection with your transactions with other
                users or other data generated primarily through your communications with them.
              </li>
            </ul>
            <p class="pb-3">
              4. <i>Financial Information</i>
              4. You agree that, for the purposes disclosed in this GDPR & Privacy Policy, we are entitled to collect
              and use the information contained in or related to your financial information when you use Prochange
              Services, including without limitation, bank account information, payment card primary account number
              (PAN), transaction history, trading data, and/or tax identification. We collect such financial information
              to monitor suspicious financial activity for user fraud protection, legal case resolution, and any other
              purposes disclosed in this GDPR & Privacy Policy.
            </p>
            <h3 class="pb-3">2. How We Collect Information</h3>
            <h6 class="pb-3">2.1 Information Prochange Automatically Collects</h6>
            <p class="pb-3">
              Most of the personal information that we collect is directly provided by you. In the following situations,
              we will collect and process the information about you:
            </p>
            <ol>
              <li class="pb-3"> where you register for, log in or visit Prochange, or use any service of Prochange;</li>
              <li class="pb-3"> where you voluntarily complete any user survey or provide feedback to us via email or
                any other channel.
              </li>
              <li class="pb-3"> where you use cookies of the browser or software in visiting or using our website or
                APPs.
              </li>
              <li class="pb-3"> other situations where we may automatically collect your information as mentioned in
                this GDPR & Privacy Policy or our T&C (including the Prochange Platform Rules, the same below).
              </li>
            </ol>
            <h6 class="pb-3">
              2.2 Information Collected from the Third-party Sources
            </h6>
            <p class="pb-3">
              We may collect information about you from third-party sources, including but not limited to, the following
              channels
            </p>
            <ol>
              <li class="pb-3"> public databases, credit bureaus and ID verification partners.</li>
              <li class="pb-3"> blockchain data;</li>
              <li class="pb-3"> marketing partners and resellers;</li>
              <li class="pb-3"> advertising partners and analytics providers.</li>
            </ol>
            <h6 class="pb-3">
              2.3 Anonymized and Aggregated Data
            </h6>
            <p class="pb-3">
              Anonymization is a data processing technique that removes or modifies personal information so that it
              cannot be associated with a specific individual. Except for this section, none of the other provisions of
              this GDPR & Privacy Policy applies to anonymized or aggregated personal data (i.e. information about our
              Users that we combine together so that it no longer identifies or references an individual User).
            </p>
            <p class="pb-3">
              We may use anonymized or aggregate customer data for any business purpose, including to better understand
              Users’ needs and behaviours, improve our products and services, conduct business intelligence and
              marketing, and detect security threats. We may perform our own analytics on anonymized data or enable
              analytics provided by third parties.
            </p>
            <h3 class="pb-3">
              3. How We Use the Information We Collect
            </h3>
            <p class="pb-3">
              We use the information we collect about you for the following purposes or in the following ways:
            </p>
            <h6 class="pb-3">
              3.1 To Provide and Maintain Prochange Services
            </h6>
            <p class="pb-3">
              We use the information collected to deliver, maintain and provide better Prochange Services (including but
              not limited to processing transactions) and verify Users’ identities.
            </p>
            <p class="pb-3">
              We use the IP address and unique identifiers stored in your device’s cookies to help us authenticate your
              identity and activities and provide Prochange Services. Given our legal obligations and system
              requirements, we cannot provide you with all or some of Prochange Services without data like
              Identification Information, Supervision Information, Service Usage Information, Communication Information,
              and Transaction Information.
            </p>
            <h6 class="pb-3">
              3.2 To Protect Our Users
            </h6>
            <p class="pb-3">We use the information collected to protect our platforms, Users’ accounts, and
              archives.</p>
            <p class="pb-3">We use IP addresses and cookies to protect against automated abuse such as spam, phishing,
              and Distributed Denial of Service (DDoS) attacks.</p>
            <p class="pb-3">We analyse trading activities with the goal of detecting suspicious behaviours as early as
              possible to prevent potential fraud and loss of funds to bad actors.</p>
            <h6 class="pb-3">
              3.3 To Comply with Legal and Regulatory Requirements
            </h6>
            <p class="pb-3">
              With respect to the privacy and security of personal information, we will use the information in
              compliance with our legal obligations, government requests, and reasonable user-generated inquiries. In
              cases where it is strictly necessary, such as to protect the vital interests of the Users or other natural
              persons, to fulfil the purpose of public interest, to pursue our reasonable interests (but not to damage
              the interests of the Users), we may process your personal information without your consent. Except for the
              situations stated in this GDPR & Privacy Policy or the T&C, we will not disclose or provide any of your
              personal information to third parties without a review from our legal team and/or prior consent from the
              User.
            </p>
            <h6 class="pb-3">
              3.4 For Measurement, Research and Development Purposes
            </h6>
            <p class="pb-3">
              We actively measure and analyse data to understand the way you use and interact with Prochange Services.
              This review activity is conducted by our operation teams to continually improve our Platforms’ performance
              and to resolve issues with the user experience. In addition, we use such information to customize,
              measure, and improve Prochange Services and the content and layout of our websites and apps, and to
              develop new services.
            </p>
            <p class="pb-3">
              We continuously monitor activity information within our systems and our communications with users to look
              for and quickly fix problems.
            </p>
            <h6 class="pb-3">
              3.5 To Communicate with You
            </h6>
            <p class="pb-3">
              We use personal information collected, like your phone number or email address to interact with you
              directly when providing customer support on a ticket or to keep you informed on logins, transactions,
              account security and other aspects. Without collecting and processing your personal information for
              confirming each communication, we will not be able to respond to your submitted requests, questions, and
              inquiries. All direct communications are properly kept at Prochange or the service provider designated by
              Prochange, to be reviewed for accuracy, to be kept as evidence, or to be used to perform other statutory
              or contractual obligations.
            </p>
            <h6 class="pb-3">
              3.6 To Enforce Our T&C and Other Agreements
            </h6>
            <p class="pb-3">
              The collected information is also used to continually and actively enforce our T&C and other agreements
              with our Users, including but not limited to reviewing, investigating, and preventing any potentially
              prohibited or illegal activities that may violate the foregoing provisions, or disclose the relevant
              information to a third party in accordance therewith.
            </p>
            <p class="pb-3">
              Prochange reserves the right to suspend or terminate provision of any Prochange Services to any User found
              to be engaged in activities that violate our T&C and other agreements with our Users.
            </p>
            <h6 class="pb-3">
              3.7 To Facilitate Corporate Acquisitions, Mergers, or Transactions
            </h6>
            <p class="pb-3">
              We may process any information regarding your Prochange Account and use of Prochange Services as is
              necessary in the context of corporate acquisitions, mergers, or other corporate transactions.
            </p>
            <h6 class="pb-3">
              3.8 For Marketing and Advertising
            </h6>
            <p class="pb-3">
              We may share your personal information with our marketing partners for the purposes of targeting,
              modeling, and/or analytics as well as marketing and advertising.
            </p>
            <h6 class="pb-3">
              3.9 For Rebates
            </h6>
            <p class="pb-3">
              We may share the information collected, including but not limited to your Identification Information and
              Transaction Information, with your referrer for the purposes of rebates and other benefits.
            </p>
            <h6 class="pb-3">
              3.10 For Any Other Purpose
            </h6>
            <p class="pb-3">
              We may disclose your personal information for any other purpose you consent to.
            </p>
            <h3 class="pb-3">
              4. Marketing
            </h3>
            <p class="pb-3">If we think you may like specific Prochange Services or products and services of our
              partners, we hope to keep you informed about such products or services.</p>
            <p class="pb-3">If you agree to receive the above marketing information, you can choose to receive it at any
              time in the future.</p>
            <p class="pb-3">You have the right to ask us at any time to stop contacting you for marketing purposes or to
              send relevant information to you. If you do not want to be contacted for marketing purposes in the future,
              please click on the unsubscribe link in the bottom of the marketing email and submit your request to
              unsubscribe.</p>
            <h3 class="pb-3">
              5. How we Store and Protect User Data
            </h3>
            <p class="pb-3">
              Prochange has kept your personal information safe using fault-tolerant secured storage with industry
              standard encryption and implemented a number of security measures to ensure that your information is not
              lost, abused, or altered, including, but not limited to:
            </p>
            <h6 class="pb-3">
              5.1 Physical Measures
            </h6>
            <p class="pb-3">
              Materials containing your personal information will be stored in a locked place.
            </p>
            <h6 class="pb-3">
              5.2 Electronic Measures
            </h6>
            <p class="pb-3">
              Computer data containing your personal information will be stored in the computer systems and storage
              media that are subject to strict log-in restrictions.
            </p>
            <h6 class="pb-3">
              5.3 Management Measures
            </h6>
            <p class="pb-3">
              Only authorized employees are permitted to come into contact with your personal information and such
              employees must comply with our internal confidentiality rules for personal data. We have also imposed
              strict physical access controls to buildings and files.
            </p>
            <h6 class="pb-3">
              5.4 Technical Measures
            </h6>
            <p class="pb-3">
              Encryption technology such as PCI Scanning and Secured Sockets Layered Encryption may be used to transmit
              and store your personal information. We use various currently available general security technologies and
              supporting management systems to minimize the risks that your information may be disclosed, damaged,
              misused, accessed without authorization, disclosed without authorization, or altered.
            </p>
            <h6 class="pb-3">
              5.5 Other Measures
            </h6>
            <p class="pb-3">Our web server is protected by the “firewall”.</p>
            <p class="pb-3">Please note that it is impossible to guarantee 100% security of information. As such, we
              request that you understand the responsibility to independently take safety precautions to protect your
              own personal information. You agree that we shall not be liable for any information leakage and other
              losses not caused by our intention or gross negligence, including but not limited to hacker attack, power
              interruption, or unavoidable technical failure, to the maximum extent permitted by law.</p>
            <p class="pb-3">If you suspect that your personal information has been compromised, especially account
              and/or password information, please lock your Prochange Account and immediately contact Prochange Customer
              Support team:
              <a href="mailto:support@exchanity.com">support@exchanity.com</a></p>
            <p class="pb-3">Unless otherwise stipulated by law or the T&C, you agree that we have the right to retain
              all the collected personal information for the duration of your Prochange Account for 5 or more years
              after the account is closed.</p>

            <h3 class="pb-3">
              6. Transfers of Personal Information
            </h3>
            <p class="pb-3">You understand that we have the right to have all or some of the collected personal
              information transferred to or stored in other countries or regions than your country of nationality, your
              country of residence, or the country where the server is, without your specific consent, under the
              following circumstances:
            </p>
            <ol>
              <li class="pb-3"> if it is necessary to protect, process, analyze, reasonably use the personal
                information.
              </li>
              <li class="pb-3"> if it is necessary to enforce the T&C and other agreements with our Users.</li>
              <li class="pb-3"> if it is necessary for the public interest.</li>
              <li class="pb-3"> if it is necessary to establish, exercise or defend the rights of us, our partners, or
                other Users.
              </li>
              <li class="pb-3"> other circumstances required by law or government orders.</li>
            </ol>

            <h3 class="pb-3">
              7. Cookies
            </h3>
            <h6 class="pb-3">
              7.1 What Are Cookies?
            </h6>
            <p class="pb-3">
              Cookies are small amounts of data that are sent to your browser and stored on your computer’s hard drive
              to collect standard internet log information and visitor behaviour information. When you visit Prochange,
              we can automatically collect information from you through cookies or similar technologies.
            </p>
            <h6 class="pb-3">
              7.2 How Do We Use Cookies?
            </h6>
            <p class="pb-3">
              We use cookies in a range of ways to make your experience on our Platforms more enjoyable, including but
              not limited to:
            </p>
            <ol>
              <li class="pb-3"> to keep you signed in;</li>
              <li class="pb-3"> to record your habits and preferences when browsing Prochange;</li>
              <li class="pb-3"> to record our performance and verify the effectiveness of online advertising through
                Google Stats with cookies.
              </li>
            </ol>
            <h6 class="pb-3">7.3 What Types of Cookies Do We Use?</h6>
            <p class="pb-3">
              We use different types of cookies, including but not limited to:
            </p>
            <p class="pb-3">
              1. <i>Functional Cookies</i><br>1. We use such cookies to help us identify you and remember your previous
              preferences and settings, which may include your device, your operating system, your preferred language,
              your location, and other session data. We use a mix of first-party and third-party cookies.
            </p>
            <p class="pb-3">
              2. <i>Marketing Cookies</i><br>
              We use these cookies to collect information about your visit to Prochange, the content you view, the links
              you follow, and other information about your browsers, devices, your IP addresses and referral URLs. We
              sometimes share certain parts of the collected information with third parties for marketing purposes. We
              may also share online data collected through cookies with our marketing partners. This means that when you
              visit another website, you may also be shown advertisements based on your browsing patterns on Prochange.
            </p>
            <h6 class="pb-3">7.4 How to Manage Cookies?</h6>
            <p class="pb-3">Most browsers are present to accept cookies. You can adjust your browser setting to prevent
              cookies or to notify you as soon as cookies are loaded. However, stopping all cookies might mean that you
              cannot access or use some features of the Prochange.</p>
            <h3 class="pb-3">
              8. Data Protection Rights that You Enjoy
            </h3>
            <p class="pb-3">
              We hope to ensure that you are fully aware of the data protection rights that you enjoy. Unless otherwise
              required by law or government orders, each User has the following rights:
            </p>
            <h6 class="pb-3">
              8.1 Right of Access
            </h6>
            <p class="pb-3">
              You have the right to access or obtain copies of your personal information. We may charge a reasonable fee
              for providing the access service.
            </p>
            <h6 class="pb-3">
              8.2 Right to Rectification
            </h6>
            <p class="pb-3">
              You have the right to correct your personal information that you deem inaccurate. You also have the right
              to ask us to complete the personal information that you deem incomplete within a reasonable limit.
            </p>
            <h6 class="pb-3">
              8.3 Right to Erasure (Right to be Forgotten)
            </h6>
            <p class="pb-3">
              You have the right to request the erasure of your personal data under certain circumstances.
            </p>
            <h6 class="pb-3">
              8.4 Right to Restriction of Processing
            </h6>
            <p class="pb-3">
              Under certain circumstances as required by law or as specifically reminded by us, you have the right to
              request us to restrict the processing of your personal information; however, you understand that such
              restriction of the processing may prevent us from providing you with some of Prochange Services.
            </p>
            <h6 class="pb-3">
              8.5 Right to Object
            </h6>
            <p class="pb-3">
              Under certain circumstances as required by Law or as specifically reminded by us, you have the right to
              object to the processing of your personal information; however, you understand that such objection of the
              processing may prevent us from providing you with some of Prochange Services.
            </p>
            <h6 class="pb-3">
              8.6 Right to Data Portability
            </h6>
            <p class="pb-3">
              Under certain circumstances as required by Law or as specifically reminded by us, you have the right to
              request us to transmit the personal information that we collect to another body, or directly to you. We
              may charge a reasonable fee for providing the transmission service.
            </p>
            <h3 class="pb-3">
              9. Privacy Policies of Other Websites
            </h3>
            <h6 class="pb-3">
              9.1 Privacy Policies of Non-Prochange Websites
            </h6>
            <p class="pb-3">
              If you visit other third-party platforms or partner’s websites, apps via the links on Prochange, you
              should agree with and abide by their separate and independent privacy policies. We are not responsible for
              the content or the activities therein.
            </p>
            <h6 class="pb-3">9.2 Privacy Policies of Prochange</h6>
            <p class="pb-3">This Privacy Policy applies to all platforms, websites, and departments of Prochange.com and
              Prochange ecosystem. If you visit any link to any sub-platforms of Prochange, you should agree with and
              abide by the separate and independent privacy policies of such sub-platforms. If the privacy policy of any
              sub-platform conflicts with this Privacy Policy, the privacy policy of such sub-platform shall
              prevail.</p>
            <h3 class="pb-3">
              10. Changes to This Privacy Policy
            </h3>
            <p class="pb-3">
              From time to time, Prochange may revise this GDPR & Privacy Policy to reflect changes in Law or our
              personal-data collection, processing, and use practices. You shall regularly review the GDPR & Privacy
              Policy and pay attention to its revisions. If you do not agree with the revised content, you shall stop
              accessing Prochange immediately. When an updated version of the GDPR & Privacy Policy is released, your
              continued access to Prochange means that you agree to the updated content and agree to abide by the
              updated GDPR & Privacy Policy.
            </p>
            <h3 class="pb-3">
              11. Contact Us
            </h3>

            <p class="pb-3">
              We are committed to respecting the freedoms and rights of all our Prochange Users. Should have any
              questions or concerns regarding this GDPR & Privacy Policy, or if you would like to file a data protection
              request, please send us request to <a href="mailto:support@exchanity.com">support@exchanity.com</a>
            </p>


          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "GDRPAndPrivacy",
  mounted() {
    document.querySelector('body').scrollIntoView({
      behavior: 'smooth'
    });
  }
}
</script>
<style scoped>
li {
  margin-left: 30px;
}

</style>
